import React, { useState, useRef } from 'react'
import styled from '@emotion/styled'

const TableControl = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .row-filter {
    margin-right: 30px;
    font-size: 14px;
    > .row-filter__input {
      border-radius: 4px 0 0 4px;
      border: 0;
      height: 30px;
      border: 1px solid #0076ff;
      padding: 5px;
    }
    > .row-filter__clear {
      font-weight: 700;
      padding: 0;
      cursor: pointer;
      height: 30px;
      width: 30px;
      border-radius: 0 4px 4px 0;
      border: 0;
      background: #0076ff;
      color: #fff;
    }
  }
`

const ColumnControlButton = styled.div`
  text-align: right;
  cursor: pointer;
  user-select: none;
  font-weight: 700px;
  font-size: 30px;
  padding: 0;
  margin: 0;
  color: #0076ff;
`

const ColumnControlSelector = styled.div`
  margin: 0;
  padding: 0;
  background: #fff;
  border: 1px solid #e0e3e9;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.12);
  font-size: 18px;
  color: #8a92a0;
  padding: 1rem;
  border-radius: 4px;
  position: absolute;
  right: 60px;
  top: 150px;
  width: 260px;
  height: 300px;
  z-index: 999;
  &::before {
    content: '';
    height: 20px;
    width: 20px;
    border-top: 1px solid #e0e3e9;
    border-right: 1px solid #e0e3e9;
    background: #fff;
    transform: rotate(-45deg);
    position: absolute;
    top: -10px;
    right: 35px;
  }
  > .selector-items__wrap {
    height: 100%;
    overflow-y: scroll;
  }
`

const ColumnControlSelectorItem = styled.div`
  cursor: pointer;
  user-select: none;
  margin-bottom: 20px;
  > label {
    cursor: pointer;
    > input {
      margin-right: 15px;
    }
  }
`

export default ({ updateFilter, allColumns }) => {
  const [columnControlOpen, toggleColumnControl] = useState(false)
  const rowFilterInput = useRef(null)

  return (
    <TableControl className="table-control">
      <div className="row-filter">
        <input
          type="search"
          ref={rowFilterInput}
          className="row-filter__input"
          onChange={e => {
            updateFilter(
              `resourceFields`,
              e.target.value.length >= 3 ? e.target.value : undefined
            )
          }}
          placeholder={`Search resources...`}
        />
        <button
          className="row-filter__clear"
          onClick={() => {
            rowFilterInput.current.value = ''
            updateFilter(`resourceFields`, undefined)
          }}
        >
          x
        </button>
      </div>
      <div className="column-control">
        <ColumnControlButton
          tabIndex="0"
          role="button"
          onClick={() => toggleColumnControl(!columnControlOpen)}
        >
          +
        </ColumnControlButton>
        {columnControlOpen ? (
          <ColumnControlSelector>
            <div className="selector-items__wrap">
              {allColumns.map(column =>
                column.id !== 'resourceFields' &&
                column.id !== 'columnControl' ? (
                  <ColumnControlSelectorItem key={column.id}>
                    <label>
                      <input
                        type="checkbox"
                        {...column.getToggleHiddenProps()}
                      />
                      {column.Header}
                    </label>
                  </ColumnControlSelectorItem>
                ) : null
              )}
            </div>
          </ColumnControlSelector>
        ) : null}
      </div>
    </TableControl>
  )
}
