import React from 'react'
import styled from '@emotion/styled'
import { debounce } from 'lodash'

import CheckMarkGrn from './checkmark-grn.svg'
import SupportTableControl from './support-table-control'

const SupportTableWrap = styled.div`
  margin-left: 350px;
  padding: 100px 100px 0 100px;
  h1 {
    margin: 0;
  }
  .support-table-wrap__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
  }
`

const SupportTable = styled.div`
  color: #666;
  background: #fff;
  width: 100%;
  height: 75vh;
  overflow-y: scroll;
`

const SupportTableHead = styled.div`
  z-index: 1;
  box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);
  position: sticky;
  top: 0;
  background: #fff;
  height: 50px;
  > .table-head__row {
    height: 100%;
    > .table-head__cell:nth-of-type(even) {
      border-left: 1px solid #e0e3e9;
      border-right: 1px solid #e0e3e9;
    }
    > .table-head__cell {
      font-size: 0.8rem;
      font-weight: 200;
      height: fit-content;
      align-self: center;
      padding: 10px;
      text-align: center;
    }
    > .table-head__cell:nth-of-type(1) {
      text-align: left;
      font-weight: 600;
      font-size: 13px;
      color: #0076ff;
    }
  }
`

const SupportTableBody = styled.div`
  > .support-row:nth-of-type(n + 2) {
    border-top: 1px solid hsla(0, 0%, 0%, 0.12);
  }
`
const SupportTableRow = styled.div`
  height: 70px;
  border-top: 1px solid hsla(0, 0%, 0%, 0.12);
  > .support-row__cell {
    padding: 10px;
  }
  > .support-row__cell:nth-of-type(even) {
    background: #fafbfc;
  }
  &:hover > .support-row__cell {
    background: #f5f6f8;
  }
`

const SupportTableCell = styled.span`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
  border: none;
  > .support-table__checkmark {
    width: 25px;
    margin: 0;
    justify-self: center;
    align-self: center;
  }
`

export default (tableInstance, pmsFieldSupport) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setFilter,
  } = tableInstance

  const isFieldSupported = (pmsKey, query) => {
    const foundValue = pmsFieldSupport
      .get(pmsKey)
      .find(field => field === query.toLowerCase())
    return foundValue === query
  }

  const updateFilter = debounce(
    (forColumn, query) => setFilter(forColumn, query),
    200
  )

  return (
    <SupportTableWrap>
      <div className="support-table-wrap__head">
        <h1>Supported Resources by PMS</h1>
        <SupportTableControl
          updateFilter={updateFilter}
          allColumns={allColumns}
        />
      </div>
      <SupportTable {...getTableProps()}>
        <SupportTableHead className="support-table__head">
          {headerGroups.map(headerGroup => (
            <div
              className="table-head__row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map(column => (
                <div className="table-head__cell" {...column.getHeaderProps()}>
                  {column.render('Header')}
                </div>
              ))}
            </div>
          ))}
        </SupportTableHead>
        <SupportTableBody
          className="support-table__body"
          {...getTableBodyProps()}
        >
          {rows.map(row => {
            prepareRow(row)
            return (
              <SupportTableRow
                className={`support-row${
                  !row.canExpand ? '__subrow' : '__grouprow'
                }`}
                {...row.getRowProps()}
              >
                {row.cells.map(cell => {
                  return (
                    <SupportTableCell
                      className="support-row__cell"
                      {...cell.getCellProps()}
                    >
                      {cell.column.id === 'resourceFields'
                        ? cell.render('Cell')
                        : cell.render(cell => {
                            if (isFieldSupported(cell.column.id, cell.row.id)) {
                              return (
                                <img
                                  className="support-table__checkmark"
                                  src={CheckMarkGrn}
                                  alt=""
                                />
                              )
                            } else {
                              return null
                            }
                          })}
                    </SupportTableCell>
                  )
                })}
              </SupportTableRow>
            )
          })}
        </SupportTableBody>
      </SupportTable>
    </SupportTableWrap>
  )
}
