import React from 'react'
import {
  useTable,
  useExpanded,
  useFlexLayout,
  useColumnOrder,
  useFilters,
  useGroupBy,
} from 'react-table'
import { get } from 'lodash'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'

import RenderTableWithInstance from '../components/support-table/render-table-with-instance'

const SupportTableResourceField = styled.span`
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  align-items: center;
  user-select: none;
  font-weight: ${props => (props.isResourceName ? '700' : '400')};
`

const ExpanderIcon = styled.span`
  display: block;
  height: 12px;
  width: 12px;
  border-top: 2px solid #8a92a0;
  border-right: 2px solid #8a92a0;
  transform: rotate(${props => (props.isExpanded ? '135deg' : '45deg')});
  transition: transform 0.25s;
`

/**
 * Custom filter for resources. Filters each row (resource field) by their parent resource.
 **/
const resourceFilter = (rows, id, query) => {
  return rows.filter(row => {
    return row.original.forResource.name
      .toLowerCase()
      .startsWith(query.toLowerCase())
  })
}

export default ({ data }) => {
  const pmsFieldSupport = new Map()
  const pmsList = data.implementations.edges.map(({ node }) => {
    const {
      pmsKey,
      apiSupport: {
        internal: { content },
      },
    } = node

    // The following doesn't effect table column data at all, but is done here since we're pulling pms data anyway.
    // Start resource field support retrieval.
    const resourceSupport = JSON.parse(content).resources
    const fieldSupport = []
    for (const resource of Object.keys(resourceSupport)) {
      const resourceFields = get(resourceSupport[resource], 'fields')
      if (resourceFields) {
        for (const field in resourceFields) {
          const fullFieldName = `${resource}-${field.toLowerCase()}`
          fieldSupport.push(fullFieldName)
        }
      }
    }
    pmsFieldSupport.set(pmsKey, fieldSupport)
    // End resource field support retrieval.

    // This is the object that will be used to populate columns.
    return {
      id: pmsKey,
      Header: pmsKey.replace('_', ' '),
      accessor: () => null,
      width: 80,
    }
  })

  const resourceFieldList = []
  const resourceList = data.resources.edges.map(({ node }) => {
    const { name, uniqueIdentifier, contentfulfields } = node

    if (contentfulfields) {
      resourceFieldList.push(
        ...contentfulfields.map(field => {
          return {
            forResource: { name, uniqueIdentifier },
            uniqueIdentifier: field.uniqueIdentifier,
            name: field.name,
          }
        })
      )
    }

    return {
      name,
      uniqueIdentifier,
    }
  })

  const tableColumns = React.useMemo(
    () => [
      {
        id: 'resourceFields',
        filter: 'resourceFilter',
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? 'Collapse' : 'Expand'} All
          </span>
        ),
        Cell: ({ row, cell }) =>
          row.canExpand ? (
            <SupportTableResourceField
              isResourceName
              {...row.getToggleRowExpandedProps()}
            >
              <span>{row.groupByVal}</span>
              <ExpanderIcon isExpanded={row.isExpanded} />
            </SupportTableResourceField>
          ) : (
            <SupportTableResourceField>
              <span>{cell.value}</span>
            </SupportTableResourceField>
          ),
        accessor: 'name',
        width: 100,
      },
      ...pmsList,
    ],
    [pmsList]
  )

  const tableInstance = useTable(
    {
      columns: tableColumns,
      data: React.useMemo(() => {
        return data.resources.edges.reduce((resourceFieldList, { node }) => {
          const { name, uniqueIdentifier, contentfulfields } = node
          if (contentfulfields) {
            resourceFieldList.push(
              ...contentfulfields.map(field => {
                return {
                  forResource: { name, uniqueIdentifier },
                  uniqueIdentifier: field.uniqueIdentifier,
                  name: field.name,
                }
              })
            )
          }
          return resourceFieldList
        }, [])
      }, [data]),
      getRowId: row => {
        // Generate row ID using the parent resources unique identifier and the resource fields name.
        // For resilience, this would ideally use the resource field uniqueidentifier also, but there are disparities between
        // API Resources and Integration Implementations in Contentful that don't make this possible.
        // For an example, view allocations-allocation-restrictions under API Resource in Contentful. Uniqueidentifier is `allocations-allocation-restrictions`, but should be `allocations-allocationrestrictions`
        // as the integration implementation api support field for this is `allocations > allocationrestriction`, which would make resolving a path to the resource field much easier.
        return `${row.forResource.uniqueIdentifier}-${row.name}`.toLowerCase()
      },
      filterTypes: React.useMemo(
        () => ({
          resourceFilter,
        }),
        []
      ),
      groupByFn: React.useMemo(
        () => (rows, columnId) => {
          const groupedRows = rows.reduce((grouped, row) => {
            grouped[row.original.forResource.name] =
              grouped[row.original.forResource.name] || []
            grouped[row.original.forResource.name].push(row)
            return grouped
          }, {})
          return groupedRows
        },
        []
      ),
      initialState: {
        columnControlOpen: false,
        groupBy: React.useMemo(() => [`resourceFields`], []),
        columnOrder: [
          'resourceFields',
          'IMPALA_PMS',
          'OPERA_ONPREMISE',
          'OPERA_HOSTED',
          'SUITE8_ONPREMISE',
        ],
        hiddenColumns: [
          'PROTEL_ONPREMISE',
          'INFOR_HMS',
          'MEWS',
          'SIHOT',
          'GUESTLINE',
          'CLOCK_PMS',
        ],
      },
    },
    useFilters,
    useGroupBy,
    useExpanded,
    useFlexLayout,
    useColumnOrder
  )

  return RenderTableWithInstance(tableInstance, pmsFieldSupport)
}

export const query = graphql`
  query SupportIndexPageQuery {
    site: site {
      siteMetadata {
        title
        description
      }
    }
    apiDocs: allContentfulApiDocs {
      edges {
        node {
          ...DocsFragment
        }
      }
    }
    resources: allContentfulApiResource {
      edges {
        node {
          name
          uniqueIdentifier
          contentfulfields {
            name
            uniqueIdentifier
          }
        }
      }
    }
    implementations: allContentfulIntegrationImplementation {
      edges {
        node {
          id
          title
          pmsKey
          apiSupport {
            internal {
              content
            }
          }
        }
      }
    }
  }
`
